<template>
  <div id="stockOutDtl">
    <Drawer
      class="bk-sub-drawer stock-out-drawer"
      v-model="flag"
      title="出库申请"
      :transfer="false"
      :mask-closable="false"
      width="500"
      @on-close="close"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          @submit.native.prevent
          :model="formd"
          :rules="rules"
          ref="form"
          inline
          :label-width="100"
        >
          <FormItem label="委托单号：">
            <div>{{formd.OrderCode}}</div>
          </FormItem>
          <FormItem label="单据编号：">
            <div>{{formd.BillCode}}</div>
          </FormItem>
          <FormItem label="单据日期：">
            <div>{{formd.BillDate}}</div>
          </FormItem>
          <FormItem label="仓库：">
            <div>{{formd.StoreName}}</div>
          </FormItem>
          <FormItem label="申请人：">
            <div>{{formd.ApplyUserName}}</div>
          </FormItem>
          <FormItem label="入库类型：">
              <div v-if="formd.SubType==1">检测出库</div>
              <div v-if="formd.SubType==2">样品处理出库</div>
              <div v-if="formd.SubType==99">其它出库</div>
          </FormItem>
          <FormItem label="备注：" class="long">
            <div>{{formd.Remark||"无"}}</div>
          </FormItem>
          <!-- <FormItem label="出库明细：" style="margin: 0 0 8px"></FormItem> -->
        </Form>
        <div style="margin: 20px 0 5px;">出库明细</div>
        <Form
          class="stock-out-item"
          v-for="(a, i) in items"
          :key="`stock-out-${i}`"
          @submit.native.prevent
          inline
        >
          <FormItem label="名称：" :label-width="42">
            <span>{{ a.Name }}</span> *
            <span>{{ a.Qty }}</span>
          </FormItem>
          <div>
            <FormItem label="原编号：" :label-width="56">
              <div style="width: 144px">{{ a.Code || "--" }}</div>
            </FormItem>
            <FormItem label="内部编号：" :label-width="70">
              <div style="width: 160px">{{ a.GoodsNo || "--" }}</div>
            </FormItem>
          </div>
          <div>
            <FormItem label="测试项目：" :label-width="70">
              <div>{{a.ItemName}}</div>
            </FormItem>
          </div>
          <FormItem label="备注：" :label-width="42" class="long">
            <div>{{a.Remark||"无"}}</div>
          </FormItem>
        </Form>
        <Form @submit.native.prevent inline :label-width="100" v-if="formd.Status==1">
          <FormItem label="审核意见：" class="long">
            <Input v-model="checkObj.Opinion" type="textarea" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="formd.Status==1">
        <Button type="primary" :disabled="posting" :loading="posting&&checkObj.Status==1" @click="check(1)">同意</Button>
        <Button type="error" :disabled="posting" :loading="posting&&checkObj.Status==2" @click="check(2)">不同意</Button>
      </div>
    </Drawer>
    
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
import PickStock from "./stockOut-pick-stock.js";
export default {
  components: { PickOrder, PickUser },
  mixins: [PickStock],
  data() {
    return {
      flag: false,
      id: 0,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {},
      items: [],
      checkObj: {},
      posting: false
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.id = id;
      this.req();
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.id = 0;
      this.formd = {};
      this.checkObj = {};
      this.items = [];
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/WmsStockOut/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data.Main;
            this.items = res.data.Items;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    check(status) {
      if (status==2 && !this.checkObj.Opinion) {
        this.$Message.warning("请输入审核意见")
        return
      }
      this.checkObj.Id = this.formd.Id;
      this.checkObj.Status = status;
      this.posting = true;
      this.$axios
        .post("/Api/WmsStockOut/Review", this.checkObj)
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.req();
            this.$emit("on-upd");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch(err => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    }
  },
};
</script>
<style lang="less">
#stockOutDtl {
  .stock-out-drawer {
    .stock-out-item {
      padding: 5px 10px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      margin-bottom: 5px;
      position: relative;
      .ivu-form-item {
        margin: 2px 0;
        width: auto;
      }
      .ivu-form-item + .ivu-form-item {
        margin-left: 10px;
      }
      .ivu-form-item-label {
        padding: 0;
        line-height: 20px;
        color: #808695;
      }
      .ivu-form-item-content {
        line-height: 20px;
      }
      .close {
        position: absolute;
        top: 0;
        right: 2px;
      }
    }
  }
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
    margin-bottom: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>


