export default {
  data() {
    return {
      flag2: false,
      searchData1: {},
      searchData2: {},
      loading2: false,
      lst: [],
      all: 0,
      pi: 1,
      ps: 10,
    }
  },
  methods: {
    open2() {
      if (!this.formd.BtmsOrderId) {
        this.$Message.warning("请先选委托单")
        return
      } else if (!this.formd.StoreId) {
        this.$Message.warning("请先选仓库")
        return
      }
      this.flag2 = true;
      this.reqStock();
    },
    close2() {
      this.flag2 = false;
    },
    reqStock() {
      let params = Object.assign(
        {
          pageIndex: this.pi,
          pageSize: this.ps,
          OrderId: this.formd.BtmsOrderId,
          StoreId: this.formd.StoreId,
          OnlyHasStock: true,
        },
        this.searchData2
      );
      this.loading2 = true;
      this.$axios
        .get("/Api/WmsStock/QueryPg", { params: params })
        .then((res) => {
          this.loading2 = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            let lst = [];
            for (let i in res.data.DataSet) {
              let a = res.data.DataSet[i];
              a.imgCount = 0;
              if (a.Img1) {
                a.imgCount++;
              }
              if (a.Img2) {
                a.imgCount++;
              }
              if (a.Img3) {
                a.imgCount++;
              }
              if (a.Img4) {
                a.imgCount++;
              }
              if (a.Img5) {
                a.imgCount++;
              }
              lst.push(a);
            }
            this.lst = lst;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading2 = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pi = 1;
      this.reqStock();
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqStock();
    },
    onStock(i) {
      let stock = this.lst[i];
      for (let item of this.items) {
        if (stock.GoodsSubType == 1) {
          if (item.GoodsId == stock.GoodsId) {
            this.$Message.warning(`明细已存在样品“${item.GoodsNo}”`)
            return
          }
        } else if (stock.GoodsSubType == 99) {
          if (item.GoodsId == stock.GoodsId) {
            this.$Message.warning(`明细已存在物料“${item.Name}”`)
            return;
          }
        }
      }
      this.items.push({
        ItemId: stock.ItemId,
        ItemName: stock.ItemName,
        Qty: 1,
        Type: stock.GoodsType,
        SubType: stock.GoodsSubType,
        GoodsId: stock.GoodsId,
        GoodsNo: stock.GoodsNo,
        Code: stock.GoodsCode,
        Name: stock.GoodsName
      })
    },
  }
}