<template>
  <div id="stockOutEdit">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer stock-out-drawer"
      title="出库申请"
      v-model="flag"
      width="500"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          :label-width="100"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem class="long" label="委托单号：" prop="BtmsOrderId" required>
            <Button
              @click="fastPickOrder"
              ghost
              size="small"
              style="margin-bottom: 8px"
              type="primary"
              v-if="$store.state.order.Id"
            >快速选择：{{ $store.state.order.Code }}</Button>
            <Input :value="formd.OrderCode" readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem class="long" label="单据编号：">
            <Input placeholder="默认系统以时间生成" v-model="formd.BillCode" />
          </FormItem>
          <FormItem label="单据日期：" prop="BillDate" required>
            <DatePicker v-model="formd.BillDate" />
          </FormItem>
          <FormItem label="仓库：" prop="StoreId" required>
            <Select v-model="formd.StoreId">
              <Option
                :key="`storage-${i}`"
                :value="item.Id"
                v-for="(item, i) in storageLst"
              >{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="申请人：" prop="ApplyUserId" required>
            <Input :value="formd.ApplyUserName" @on-clear="unPickUser" clearable readonly>
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="出库类型：" prop="SubType" required>
            <!-- <Select v-model="formd.Type" style="width:132px">
              <Option :value="1">客户物料入库</Option>
              <Option :value="2" disabled>我方物料入库</Option>
            </Select>-->
            <Select v-model="formd.SubType">
              <Option :value="1">检测出库</Option>
              <Option :value="2">样品处理出库</Option>
              <Option :value="99">其它出库</Option>
            </Select>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-model="formd.Remark" />
          </FormItem>
          <FormItem class="long" label="出库明细：" style="margin: 0 0 8px">
            <Button @click="open2" ghost icon="md-add" size="small" type="primary">添加</Button>
          </FormItem>
        </Form>
        <Form
          :key="`stock-out-${i}`"
          :model="lst[i]"
          :ref="`form-${i}`"
          :rules="rules2"
          @submit.native.prevent
          class="stock-out-item"
          inline
          v-for="(a, i) in items"
        >
          <Button @click="del(i)" class="close" icon="md-close" type="text"></Button>
          <FormItem :label-width="42" label="名称：">
            <div>{{ a.Name }}</div>
          </FormItem>
          <FormItem :label-width="70" label="出库数量：">
            <Input size="small" style="width: 50px" v-model="a.Qty" />
          </FormItem>
          <div>
            <FormItem :label-width="56" label="原编号：">
              <div style="width: 144px">{{ a.Code || "--" }}</div>
            </FormItem>
            <FormItem :label-width="70" label="内部编号：">
              <div style="width: 160px">{{ a.GoodsNo || "--" }}</div>
            </FormItem>
          </div>
          <div>
            <FormItem :label-width="70" label="测试项目：">
              <Select size="small" style="width: 160px" v-model="a.ItemId">
                <Option
                  :key="`item-${i}-${b.Id}`"
                  :value="b.Id"
                  v-for="b in orderItems"
                >{{ b.Name }}</Option>
              </Select>
            </FormItem>
          </div>
          <FormItem :label-width="42" class="long" label="备注：">
            <Input size="small" style="width: 400px" v-model="a.Remark" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer">
        <Button
          :loading="posting"
          @click="ok(0)"
          ghost
          type="primary"
          v-if="id==0||formd.Status==0"
        >保存为草稿</Button>
        <Button :loading="posting" @click="ok(1)" type="primary" v-if="id==0||formd.Status==0">申请出库</Button>
        <Button :loading="posting" @click="ok(1)" type="primary" v-if="formd.Status==1">保存</Button>
      </div>
    </Drawer>

    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close2"
      class="bk-sub-drawer pick-stock-drawer"
      title="选择库存"
      v-model="flag2"
      width="500"
    >
      <div class="bk-drawer-body">
        <Input
        v-model="searchData1.Keyword"
          @on-enter="onSearch"
          placeholder="输入名称"
          style="width: auto; margin-bottom: 10px"
          suffix="ios-search"
        />
        <div class="bk-loading" style="padding:10px 0" v-if="loading2">
          <div class="text-primary flex items-center">
            <Icon type="ios-loading"></Icon>
            <span>&nbsp;Loading</span>
          </div>
        </div>
        <div
          class="text-off"
          style="padding:10px 0"
          v-if="!loading2&&lst.length==0"
        >该委托单在仓库中没有样品/物料，无法出库。</div>
        <div :key="i" class="goods-item" v-for="(item, i) in lst">
          <Button @click="onStock(i)" class="add" size="small" type="primary">选择</Button>
          <div class="flex-sub">
            <div class="text-xs">
              <span class="text-sub">名称：</span>
              <span>{{ item.GoodsName }}；</span>
              <span class="text-sub">库存数量：</span>
              <span>{{ item.Qty }}；</span>
            </div>
            <div class="text-xs">
              <span class="text-sub">内部编号：</span>
              <span>{{ item.GoodsNo }}；</span>
              <span class="text-sub">原编号：</span>
              <span>{{ item.GoodsCode || "无" }}；</span>
            </div>
            <div class="text-xs">
              <span class="text-sub">测试项目：</span>
              <span>{{ item.ItemName }};</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bk-drawer-footer">
        <Page
          :current="pi"
          :page-size="ps"
          :total="all"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          style="flex: 1; text-align: left; line-height: 32px"
        />
      </div>
    </Drawer>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
import PickStock from "./stockOut-pick-stock.js";
export default {
  components: { PickOrder, PickUser },
  mixins: [PickStock],
  data() {
    return {
      flag: false,
      id: 0,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        BtmsOrderId: [{ required: true, type: "number", message: " " }],
        BillDate: [
          {
            validator: (rule, value, callback) => {
              value ? callback() : callback(new Error(" "));
            },
          },
        ],
        StoreId: [{ required: true, type: "number", message: " " }],
        ApplyUserId: [{ required: true, type: "number", message: " " }],
        SubType: [{ required: true, type: "number", message: " " }],
      },
      items: [],
      rules2: {},
      storageLst: [],
      orderItems: [],
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.id = id;
        this.req();
      } else {
        this.formd.Type = 1;
        this.formd.BillDate = new Date();
        this.formd.ApplyUserId = this.$store.state.user.Id;
        this.$set(this.formd, "ApplyUserName", this.$store.state.user.UserName);
      }
      this.reqStorages();
      if (this.formd.BtmsOrderId) {
        this.reqItems();
      }
    },
    close() {
      this.flag = false;
      this.clear();
    },
    clear() {
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.id = 0;
      this.$refs.form.resetFields();
      this.formd = { Type: 1, SubType: 1, Status: 0 };
      this.items = [];
      this.close2();
    },
    ok(status) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // for (let a of this.lst) {
          //   if (a.Qty > a)
          // }
          this.postData(status);
        }
      });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/WmsStockOut/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data.Main;
            if (this.formd.BtmsOrderId) {
              this.reqItems();
            }
            this.items = res.data.Items;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    fastPickOrder() {
      this.formd.BtmsOrderId = this.$store.state.order.Id;
      this.$set(this.formd, "OrderCode", this.$store.state.order.Code);
      this.reqItems();
      if (this.flag2) {
        this.onChangePage(1);
      }
    },
    toPickOrder() {
      if (this.formd.BtmsOrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.BtmsOrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      this.orderItems = [];
      this.formd.ItemId = 0;
      if (lst[0]) {
        this.formd.BtmsOrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        this.reqItems();
        if (this.flag2) {
          this.onChangePage(1);
        }
      } else {
        if (this.flag2) {
          this.close2();
        }
        this.formd.BtmsOrderId = "";
        this.$set(this.formd, "OrderCode", "");
      }
    },
    // 删除入库单物料明细
    del(i) {
      this.items.splice(i, 1);
    },
    // 选择人员
    toPickUser() {
      let def = [];
      if (this.formd.ApplyUserId) {
        def = [
          {
            Id: this.formd.ApplyUserId,
            UserName: this.formd.ApplyUserName,
          },
        ];
      }
      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.ApplyUserId = lst[0].Id;
        this.$set(this.formd, "ApplyUserName", lst[0].UserName);
      } else {
        this.formd.ApplyUserId = "";
        this.$set(this.formd, "ApplyUserName", "");
      }
    },
    unPickUser() {
      this.formd.ApplyUserId = "";
      this.$set(this.formd, "ApplyUserName", "");
    },
    // 仓库
    reqStorages() {
      this.$axios.get("/Api/WmsStorage/QueryAll").then((res) => {
        if (res.result) {
          this.storageLst = res.data;
        }
      });
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.formd.BtmsOrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    postData(status) {
      let param = {};
      param.Main = this.$util.copy(this.formd);
      param.Main.Status = status;
      if (this.formd.BillDate != null && typeof this.formd.BillDate == "object") {
        param.Main.BillDate = this.formd.BillDate.format("yyyy-MM-dd");
      }
      param.Items = this.items;
      this.posting = true;
      this.$axios
        .post(
          this.id > 0 ? "/Api/WmsStockOut/UpdateEx" : "/Api/WmsStockOut/AddEx",
          param
        )
        .then((res) => {
          if (res.result) {
            this.$Message.success("提交成功");
            this.id > 0 ? this.$emit("on-upd") : this.$emit("on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
#stockOutEdit {
  .stock-out-drawer {
    .stock-out-item {
      padding: 10px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      margin-bottom: 10px;
      position: relative;
      .ivu-form-item {
        margin: 0;
        width: auto;
      }
      .ivu-form-item + .ivu-form-item {
        margin-left: 10px;
      }
      .ivu-form-item-label {
        padding: 10px 0;
        color: #808695;
      }
      .close {
        position: absolute;
        top: 0;
        right: 2px;
      }
    }
  }
  .pick-stock-drawer {
    .ivu-drawer-mask {
      background-color: transparent;
      right: 500px;
    }
    .ivu-drawer-wrap {
      right: 500px;
      border-right: 1px solid #dcdee2;
    }
    .goods-item {
      display: flex;
      padding: 5px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      margin-bottom: 10px;
      position: relative;
      .img-box {
        display: block;
        width: 58px;
        height: 58px;
        border-radius: 4px;
        flex-shrink: 0;
        margin-right: 5px;
        position: relative;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
        img:hover {
          box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
        }
        span {
          position: absolute;
          top: 0;
          right: 0;
          padding: 0 2px;
          border-bottom-left-radius: 4px;
          color: #fff;
          background: rgba(0, 0, 0, 0.3);
          font-size: 12px;
        }
      }
      .add {
        position: absolute;
        top: 1px;
        right: 1px;
      }
    }
  }
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>


